@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';


.option {
  flex:            1 0;
  display:         flex;
  flex-direction:  column;
  position:        relative;
  color:           $BLUE_HEADING;
  box-shadow:      0 .5rem 1rem .0625rem rgba(0, 0, 0, 0.16), 0 -.0625px .5px 0 rgba(0, 0, 0, 0.05);
  border-radius:   .875rem;
  padding:         1.75rem 1.25rem 1.25rem;
  justify-content: space-between;
  align-self:      stretch;
  //margin:          0 0 1rem;

  //&:first-child {margin-left: 0;}
  //
  //&:last-child {
  //  margin-right:  0;
  //  margin-bottom: 0;
  //}

  //@media (min-width: $SCREEN_TABLET) {
  //  //margin: 0 1rem;
  //}

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  &:global(.standard):before,
  &:global(.plus):before,
  &:global(.extra):before,
  &:global(.volume50):before,
  &:global(.volume100):before,
  &:global(.volume200):before,
  &:global(.starter):before,
  &:global(.deluxe):before,
  &:global(.premier):before,
  &:global(.hab):before,
  &:global(.hab-rpm):before,
  &:global(.cash-manager):before {
    background-color: $QKN_RED;
  }

  &:global(.acme-basic):before,
  &:global(.acme):before {
    background-color: $QKN_BLURPLE;
  }
}


.name {
  font-family:   $HAFFER_BOLD;
  margin-bottom: .5rem;
  flex:          0 1 2.5rem;
  font-size:     1.125rem;
}


.tag {
  font-size:     .75rem;
  font-family:   $HAFFER;
  color:         $TAG_LINE;
  margin-bottom: 1rem;
  flex:          0 1 2.75rem;
}


.price {
  // flex:          1 0;
  margin-bottom: 1rem;

  @media (min-width: $SCREEN_TABLET) {
    margin-bottom: 3rem;
  }
}


.btnRow {
  flex: 0 1 2rem;

  button {
    width:  100%;
    height: 2.25rem;
  }

  a {
    width:           100%;
    display:         block;
    padding:         .4375rem 0;
    align-self:      auto;
    text-align:      center;
    text-decoration: none;

    @media (min-width: $SCREEN_SM_DESKTOP) {
      align-self: flex-start;
      flex:       0 1 9.375rem;
    }
  }
}


.inactiveBtn,
.activeBtn {
  font-size:     .875rem;
  font-family:   $HAFFER_BOLD;
  outline:       none;
  border-width:  .0625rem;
  border-style:  solid;
  border-radius: 1.25rem;
  cursor:        pointer;

  &:disabled {
    cursor: not-allowed;
  }
}


.activeBtn {
  background-color: $QKN_BLURPLE;
  border-color:     $QKN_BLURPLE;
  color:            $WHITE;
}


.inactiveBtn {
  background-color: $WHITE;
  border-color:     $TAG_LINE;
  color:            $TAG_LINE
}
