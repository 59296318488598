@import 'bm-sub-classes.module';
@import 'src/sass/buttons.module';
@import 'src/sass/themes/colors';
@import '../tile.module';


.benefit {
  font-size: .75rem;
  color:     $TAG_LINE;
  margin:    1.25rem 0;
}

.qknTagLine {
  composes: qknTagLine;
  margin-bottom: 1rem;
}


.bmTilePayment {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .topUpWrapper {
    flex-direction: column;
  }

  // .bmTilePayMeth {
  //   display: flex;
  //   flex-direction: column;
  // }
}

.bmExpiryPromo {
  margin: 1rem 0rem;
}