@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';


.card {
  border-radius:    .8125rem;
  background-color: $WHITE;
  margin-bottom:    1.125rem;
  box-shadow:       0 .375rem .75rem .0625rem rgba(0, 0, 0, 0.24);
  position:         relative;

  @media (min-width: $SCREEN_TABLET) {
    box-shadow:    0 .375rem .75rem 0 rgba(0, 0, 0, 0.16), 0 -.0625rem .375rem 0 rgba(0, 0, 0, 0.05);
    padding:       1.875rem 2rem 1.375rem;
    margin-bottom: 2rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: .75rem;
    width: 100%;
    left: 0;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  &:global(.standard),
  &:global(.plus),
  &:global(.extra),
  &:global(.volume50),
  &:global(.volume100),
  &:global(.volume200),
  &:global(.qob-basic) {
    box-shadow: none;
    background-color: $ADDON_GREY;
    border: 1px solid $ADDON_BORDER_GREY;
    padding: 0.75rem;
  }

  // &:global(.standard):before,
  // &:global(.plus):before,
  // &:global(.extra):before,
  // &:global(.volume50):before,
  // &:global(.volume100):before,
  // &:global(.volume200):before,
  &:global(.starter):before,
  &:global(.deluxe):before,
  &:global(.premier):before,
  &:global(.hab):before,
  &:global(.hab-rpm):before,
  &:global(.cash-manager):before {
  // &:global(.qob-basic):before {
    background-color: $QKN_RED;
  }

  &:global(.acme-basic):before,
  &:global(.qem-basic):before,
  &:global(.acme):before {
    background-color: $QKN_BLURPLE;
  }
}


.infoExpiryRow {
  margin-bottom: 1rem;
  align-self: flex-start;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    align-self: flex-start;
    // margin:     0;
  }
}


.priceRow {
  text-align: left;
  margin:     .3125rem 0 .3125rem;
  font-family: $HAFFER_BOLD;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    margin:     0;

    & span:first-child {
      font-size: 1.3125rem;
    }

    & span:nth-child(2) {
      font-size: .875rem;
    }

    & span:nth-child(3) {
      font-size: .625rem;
    }
  }
}
